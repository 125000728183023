<template>
	<v-app>
		<home-app-bar />

		<home-view />

		<home-footer />

		<!-- <home-settings /> -->
	</v-app>
</template>

<script>
import HomeAppBar from "@/layouts/home/AppBar";
import HomeFooter from "@/layouts/home/Footer";
import HomeView from "@/layouts/home/View";
import HomeSettings from "@/layouts/home/Settings";

export default {
	name: "HomeLayout",

	components: {
		HomeAppBar,
		HomeFooter,
		HomeView,
		HomeSettings,
	},
};
</script>


<style>
/* 美化滚动条样式 
::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	background-color: white;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	visibility: hidden;
}

::-webkit-scrollbar-thumb:vertical {
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-color: darkgrey;
}

::-webkit-scrollbar-thumb:horizontal {
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-color: darkgrey;
}

::-webkit-scrollbar:window-inactive {
	visibility: hidden;
}

::-webkit-scrollbar-thumb:window-inactive {
	visibility: hidden;
}
*/
</style>