<template>
    <v-footer id="home-footer" dark>
        <v-container>
            <v-row>
                <v-col cols="6">
                    <small>
                        <a
                            href="https://beian.miit.gov.cn"
                            target="_blank"
                            style="text-decoration: none"
                            >京ICP备17005268号</a
                        >
                        <a
                            href="http://www.beian.gov.cn/"
                            target="_blank"
                            class="ml-4"
                            style="text-decoration: none"
                            >京公网安备11010202007435号</a
                        >
                    </small>
                    <!-- <div
						class="
							d-flex
							flex-wrap
							justify-md-start justify-center justify-md-none
						"
					>
						<template v-for="(s, i) in social">
							<a
								:key="s"
								class="white--text pa-1 pa-md-0"
								href="#"
								v-text="s"
							/>

							<v-responsive
								v-if="i < social.length - 1"
								:key="`divider-${s}`"
								class="mx-4 shrink hidden-sm-and-down"
								max-height="24"
							>
								<v-divider vertical />
							</v-responsive>
						</template>
					</div> -->
                </v-col>

                <v-col class="text-right" cols="6">
                    <small>
                        {{ $t("copyright") }} &copy;
                        {{ new Date().getFullYear() }} {{ $t("sdcoc") }}
                    </small>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
export default {
    name: "HomeFooter",

    data: () => ({
        social: ["Facebook", "Twitter", "Instagram", "Linkedin"],
    }),
};
</script>
